<template>
  <acao v-on:exit="valtarParaAcao" />
</template>

<script>
import acao from '@/components/my-components/form/acao.vue';

export default {
  components: { acao },
  methods: {
    valtarParaAcao() {
      this.$router.push('/user-supervisor/listagem/acoes');
    },
  },
};
</script>
